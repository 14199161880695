import React from "react";
import Joi from "joi-browser";
import Form from "../components/common/Form";
import config from "./../config.json";
import httpService from "../service/httpService";

class LoginForm extends Form {
  state = {
    data: { email: "", password: "" },
    errors: {},
    tokenState:""
  };
  schema = {
    email: Joi.string()
      .required()
      .email()
      .label("E-mail"),
    password: Joi.string()
      .required()
      .max(50)
      .label("Message")
  };

   handleSubmit =  async (e) =>  {
    e.preventDefault();
    const {data} = this.state;
    
    try{
      this.state.tokenState
 =  await httpService.post(config.apiEndPoint +"/members/login", {
              email: data.email,
              password: data.password
          });
      localStorage.setItem("token", this.state.tokenState.data.token);

    }catch(e) {
      console.log(e);
    }
    if(this.state.tokenState.data.token === "" || this.state.tokenState.data.token === undefined ){
      console.log("unsuccess");
      window.location.href = "/login/?Unsuccessful";
    } else {
      console.log("success");
      window.location.href = "/?LoginSuccessful";
    }
        
        
    
  };

  render() {

    return (
      <div style={{maxWidth: 700, margin: "auto", marginBottom: 20}} id="contact-form">
        <h1 style={{textAlign: "center"}} >Login</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("email", "Email")}
          {this.renderInput("password", "Password", "password")}
          {this.renderButton("Send")}
        </form>
      </div>
    );
  }
}

export default LoginForm;
