import React from 'react';

function Dashboard(props) {
    return (
        <div>
            Dashboard
        </div>
    );
}

export default Dashboard;