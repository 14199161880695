import React from 'react';
import config from "../../config.json";

function TextBox({ name, label, error, ...rest} ) {
    return (
        <div className="form-group">
      <label htmlFor={name}>{label}</label>
      <textarea {...rest} name={name} id={name} className="form-control" style={{backgroundColor: config["colour-schema"]["input-white"]}}/>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
    );
}

export default TextBox;