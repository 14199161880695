import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

import EditForm from '../forms/EditForm';

function Edit(props) {
    let {what} = useParams("what");
    const [value, setValue] = useState("")
    const [user, setUser] = useState({"result":{"name":""}});
    useEffect(()=>{

            try{
                    const jwt = localStorage.getItem("token");
                    setUser(jwtDecode(jwt));
            } catch(e){
                window.location.href = "/noAuthorised"
                    
            }
        },[user.result.name]);


    return (
        <div>
            Editing: {what}
            <EditForm field = {what} id = {user.result["ID"]}/> 
        </div>
    );
}

export default Edit;