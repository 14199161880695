import React from 'react';

function Footer(props) {
    return (
        <div>
            <ul style={{listStyle: "none"}}>
                <li> (c) Hypnos - Infinitum</li>
            </ul>
        </div>
    );
}

export default Footer;