import React, { useState, useEffect } from 'react';
import {  useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'


import httpService from '../service/httpService';
import config from "./../config.json";
import MySpinner from '../components/common/MySpinner';
import { Button } from 'react-bootstrap';
import SideBarInfo from '../components/SideBarInfo';



function hasData(arr){
    return arr != null;
};

function Member()  {
    let {id} = useParams("id");
    let [profile, setProfile] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const {data} =  await httpService.get(config.apiEndPoint + "/members/" + id);
            setProfile( data.data);
        }
        if (profile == null) fetchData();
      });

      const isDesktopOrLaptop = useMediaQuery({
        query: '(max-width: 800px)'
      });

     

     
      

        return (
            <>
            {hasData(profile) ? 
            
            <>
            
                <div className='container' style={setGridLayout() }>
                    <SideBarInfo 
                    profileInfo = {profile}/>
                    <div style={{gridArea:"name", borderBottom: "2px solid "+config['colour-schema']['brown-border']}}>
                        <h1>
                        {profile["firstname"] + " " + profile["lastname"]}
                        </h1>

                    </div>
                    {/* <div style={{gridArea:"dod"}}>
                        {profile["DOB"] ? profile["DOB"] : "This member haven' updated they date of birth yet!"} {profile["DOD"] ? " - " + profile["DOD"] : <></>}
                    </div> */}
                    <div style={{gridArea:"shortBio"}}>
                        
                        
                    </div>

                </div>
            </>
            
            
            
            
            : 
            <MySpinner />}
            <div>

                <Button variant='warning' onClick={() => {window.location.href = "/members"}} >Back</Button>
            </div>
            </>
        );
    

    function setGridLayout() {
        const gridContainer = {
            padding: 0,
            marginTop: "20px",
            
            display: "grid",
            gridTemplateColumns: "1fr 20px .5fr 20px",
            gridTemplateRows: "1rem 2rem 300px auto",
            gridTemplateAreas: `
                " name . . ."
                " name . persnalInfo . "
                "shortBio . persnalInfo ."
                "shortBio . persnalInfo . "
                `,
        };

        if (isDesktopOrLaptop) {
            gridContainer["gridTemplateAreas"] = `
            "name name "
          "persnalInfo persnalInfo "
          "shortBio shortBio "`;
            gridContainer["gridTemplateRows"] = "1fr auto auto auto";
            gridContainer["gridTemplateColumns"] = "1fr 1fr";

        }
        return gridContainer;
    }
}
 
export default Member;