
import CardGroup from 'react-bootstrap/CardGroup';
import MyCard from './card/card';

function ThreeCards({children}) {

  function renderCard(title, text, buttonLink, buttonText) {
    return(
        <MyCard 
        title = {title}
        text = { text}
        buttonLink = {buttonLink}
        buttonText = {buttonText}
      />)
  }
  return (
    <CardGroup className='container' style={{marginTop: 15}}>
      {children}
      
      {/* <Card>
        <Card.Body>
          <Card.Title>Business analysis</Card.Title>
          <Card.Text>
            Thinking of digitalising your business or improving current solution? Contact us for more information. 
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <Button onClick={()=>window.location.href = "/#contact-form"}>Contact us</Button>
        </Card.Footer>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Software solutions</Card.Title>
          <Card.Text>
            We offer complete solution for websites, custom desktop software or mobile aplications.
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <Button onClick={()=>window.location.href = "/#contact-form"}>Contact us</Button>
        </Card.Footer>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>AI and machine learning solutions</Card.Title>
          <Card.Text>
            Make your business smarter with AI solutions!
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <Button onClick={()=>window.location.href = "/#contact-form"}>Contact us</Button>
        </Card.Footer>
      </Card> */}
    </CardGroup>
  );
}

export default ThreeCards;