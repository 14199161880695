import React, {useEffect, useState} from "react";
import Banner from "../components/Banner";
import ThreeCards from "../components/ThreeCards";
import ContactForm from '../forms/ContactForm';
import MyCard from './../components/card/card';
import jwtDecode from "jwt-decode";
import mainBannerImage from "../assets/images_defaults/treeDoorSmall.jpg"

function Home (){
        const card1desc = "The objective of this project is to collect data about the family, making it easier to plan reunions and strengthen family ties. By learning more about each other's background and heritage, family members can better understand and connect with one another, leading to a closer-knit family dynamics."
        
        const [user, setUser] = useState({"result":{"name":""}});
        useEffect(()=>{

                try{
                        const jwt = localStorage.getItem("token");
                        setUser(jwtDecode(jwt));
                        console.log(user.result.name)
                } catch(e){
                        
                }
                
    },[user.result.name]);
        return <React.Fragment>
                <Banner
                        imageLink={mainBannerImage}
                        prompText = {user.result.name === "" ? "Your gateway to the family!" : "Welcome " + user.result.name }
                        />
                <ThreeCards>
                        <MyCard
                        title = {"Idea to brings dispersed family closer again!"}
                        text = { card1desc }
                        buttonLink = {"/#contact-form"}
                        buttonText = {"Contact us"}
                        
                        />
                </ThreeCards>
                <ContactForm />
                
        </React.Fragment>
}
  
export default Home;