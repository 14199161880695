import React from "react";
import Joi from "joi-browser";
import Form from "../components/common/Form";
import httpService from "../service/httpService";
import config from "./../config.json";

class RegisterForm extends Form {
  state = {
    data: { email: "", relation: "", firstname:"", surname:"" },
    errors: {}
  };
  schema = {
    email: Joi.string()
        .required()
        .email()
        .label("E-mail"),
    firstname: Joi.string()
        .required()
        .label("First name"),
    relation: Joi.string()
        .required()
        .label("Next of kin"),
    surname: Joi.string()
        .required()
        .label("Surname"),
  };

  handleSubmit =  async (e) =>  {
    e.preventDefault();
    const {data} = this.state;
        const res11 = await httpService.post(config.apiEndPoint + "/members/register", {
            name: data.firstname,
            surname: data.surname,
            email: data.email,
            shortBio: data.relation
          }).then (()=> window.location.href = "/?registrationProceed");
        console.log(res11);
  };

  render() {
    return (
      <div style={{maxWidth: 700, margin: "auto", marginBottom: 20}} id="contact-form">
        <h1 style={{textAlign: "center"}} >Register</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("email", "Your email")}
          {this.renderInput("firstname", "Your first name")}
          {this.renderInput("surname", "Your surname")}
          {this.renderTextBox("relation", "Next of kin")}
          {this.renderButton("Register")}
        </form>
      </div>
    );
  }
}

export default RegisterForm;
