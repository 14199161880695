import React, {useEffect, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import jwtDecode from 'jwt-decode';

function Navigation() {
  const [user, setUser] = useState({"result":{"name":""}});
    useEffect(()=>{

        try{
                const jwt = localStorage.getItem("token");
                setUser(jwtDecode(jwt));
        } catch(e){
                
        }
                
    },[user.result.name]);




  return (
    <Navbar bg="light" expand="lg" style={{borderBottom: "2px solid green"}}>
      <Container>
        <Navbar.Brand href="/">Congress Zavacky</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          

          <Nav className="me-auto">
            <Nav.Link href='/'>Home</Nav.Link>    
            <Nav.Link href='/members'>Members</Nav.Link>   
            { user.result.name === "" ? 
            <Nav.Link href='/contact'>Contact</Nav.Link> :
            <Nav.Link href='/dashboard'>Dashboard</Nav.Link>}
            
          </Nav>
          <Nav className="me-2">
          { user.result.name === "" ? 
            <Nav.Link href='/login'>  Login</Nav.Link>:
            <NavDropdown title={user.result.name} id="basic-nav-dropdown">
              <NavDropdown.Item href={"/members/" + user.result.ID}>My profile</NavDropdown.Item>
              <NavDropdown.Item href="/accountSettings">
                Account settings
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href='/?loggedOut' onClick={() =>{setUser({"result":{"name":""}}); localStorage.removeItem("token") }}>  Log out
              </NavDropdown.Item>
            </NavDropdown>
            }

          </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;