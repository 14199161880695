import React from 'react';

const Banner = ( {imageLink, prompText}) => {
    
        const gridContainer = { 
            
            backgroundImage: `url("`+imageLink+`")`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat", 
            backgroundSize: "cover",
            height: "75vh",
            display: "grid",
        };

        const headerStyle ={
            backgroundColor: "hsla(0, 0%, 100%, .7)",
            textAlign:'center',
            alignSelf: "center",
            
        }


        return (
            <React.Fragment>
                <div style={gridContainer}>
                        <h1 style={headerStyle}>{prompText}</h1>
                </div>
            </React.Fragment>
        );
    
  };



 
export default Banner;