import React, { Component } from 'react';
import LoginForm from '../forms/LoginForm';
import { Button } from 'react-bootstrap';

class Login extends Component {
    state = {  } 
    render() { 
        return (
            <>
            <LoginForm />
            Don't have an account? <Button variant="success" onClick={() => window.location.href = "/register"}>Register here</Button>
            <p>
                You can also register yourself by sending a request to <a href='jakub@zavacky.online'>jakub@zavacky.online</a>
            </p>
            
            </>
        );
    }
}
 
export default Login;