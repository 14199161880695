import React, {useEffect, useState} from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import jwtDecode from 'jwt-decode';


function AccountSettings(props) {

    const [user, setUser] = useState({"result":{"name":""}});
    useEffect(()=>{

            try{
                    const jwt = localStorage.getItem("token");
                    setUser(jwtDecode(jwt));
            } catch(e){
                window.location.href = "/noAuthorised"
                    
            }
        },[user.result.name]);

        const itemContainer = {
            border: "1px green solid", 
            display: "grid",
            gridTemplateAreas:` "name value button"`,
            gridTemplateColumns: "0.5fr 1fr 1fr",
            columnGap: "50px"
        }
        const gridItemName = {
            gridArea: "name",
            justifySelf: "start", 
            alignSelf:"center"
        
        }
        const gridItemValue = {
            gridArea: "value",
            justifySelf: "start", 
            alignSelf:"center"
        }

        const gridItemButton = {
            gridArea: "button",  
            justifySelf: "end" , 
            width:"70px"
        }

        function openEdit(field) {
            window.location.href = "/edit/"+field
        }



    return (
        <>
        <h1 style={{marginTop: 15, marginBottom: 15}}>{user.result.name}'s settings</h1>
        <ListGroup variant="flush" className='container'>
            {
                Object.keys(user.result).map(
                    
                    field =>  <>
                    {(field != "ID" && field != "role")?
                        <ListGroup.Item style={itemContainer}  key={field}>
                            <div style={gridItemName}>{field}</div>
                            <div style={gridItemValue}>{user.result[field]}</div>
                            <Button style={gridItemButton} variant="success" onClick={()=> openEdit(field)} > EDIT </Button>
                        </ListGroup.Item>
                    : <></>}
                    
                    </>

                    
                )
                
            }

        </ListGroup>



        
        </>
    );
}

export default AccountSettings;