import React from 'react';


import Spinner from 'react-bootstrap/Spinner';

function MySpinner(props) {
    return (
        <>
                    <Spinner variant="success" style={ {margin:'auto',} } animation="border" role="status" >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner> 
                    <h2>
                        Loading...
                    </h2>
                
                </>
    );
}

export default MySpinner;