import React, { Component } from 'react';
import {
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';


import AccountSettings from './pages/AccountSettings';
import Contact from './pages/Contact';
import Dashboard from './pages/Dashboard';
import ErrorPage from './pages/Error';
import Home from './pages/Home'
import Login from './pages/Login';
import Member from './pages/Member';
import Members from './pages/Members';

import Footer from "./components/Footer";
import Navigation from './components/Navigation';
import RegisterForm from './forms/RegisterForm';
import Edit from './pages/Edit';



class App extends Component {
  state = { 
    token: "",
   } 

   router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/members",
      element: <Members /> ,
    },
    {
      path: "/members/:id",
      element: <Member /> ,
    },
    {
      path: "/contact",
      element: <Contact /> ,
    },
    {
      path: "/login",
      element: <Login /> ,
    },
    {
      path: "/register",
      element: <RegisterForm /> ,
    },
    {
      path: "/dashboard",
      element: <Dashboard/>
    },
    {
      path: "/accountSettings",
      element: <AccountSettings />
    },
    {
      path:"/edit/:what",
      element: <Edit />
    }

  ]);

  render() { 
    return (
      <>
      
      <div className="App" style={{backgroundColor: "#e2dbc8", minHeight: "100vh"}}>
        <Navigation />
        <RouterProvider router={this.router} />
        <Footer />
      </div>
      </>



    );
  }
}
 
export default App;

