import React from 'react';
import noImagePerson from "../assets/images_defaults/no-image-person.png";
import config from "../config.json";

function SideBarInfo({profileInfo}) {

    const detailSidebar = {
        gridArea:"persnalInfo", 
        margin: "none", 
        width: "100%", 
        borderLeft: "2px solid "+ config['colour-schema']['brown-border'],
        borderTop: "2px solid "+ config['colour-schema']['brown-border']
      }

      const detailImage ={
        gridArea: "photo",
        width: "100%"
    }

    const detailsPart = {
        gridArea: "details",
        display: "grid",
        gridTemplateColumns: "auto auto",
        padding: "5px"
    }
    const publicInfo = [];
    return (
        <div style={detailSidebar} >
            <img alt={'profile image of ' + profileInfo["name"] + " " + profileInfo["surname"]}  style={detailImage} src= {profileInfo["avatar"] ? profileInfo["avatar"] : noImagePerson } />
            <div style = {detailsPart} >
                {publicInfo.map(
                    ff =><>
                        <div> {renameFields(ff)}</div>
                        <div> {profileInfo[ff]}</div>
                    </>
                )}
            </div>
        </div>
    );
}

function renameFields(field){
    if (field === "DOB"){
        return "Born: "
    }
    if (field === "DOD"){
        return "Died: "
    }
    if (field === "work"){
        return "Occupation: "
    }
}

export default SideBarInfo;