import React from "react";
import Joi from "joi-browser";
import Form from "../components/common/Form";
import emailjs from '@emailjs/browser';

class ContactForm extends Form {
  state = {
    data: { email: "", message: "" },
    errors: {}
  };
  schema = {
    email: Joi.string()
      .required()
      .email()
      .label("E-mail"),
    message: Joi.string()
      .required()
      .min(20)
      .max(500)
      .label("Message")
  };

  handleSubmit =  (e) =>  {
    e.preventDefault();
    
        emailjs.send('service_vhv4ear', 'template_57iiew8', this.state["data"], 'N9676mDVo-SRt7vOU'); 
        window.location.href = "/?messageSent";
        
        
    
  };

  render() {

    return (
      <div style={{maxWidth: 700, margin: "auto", marginBottom: 20}} id="contact-form">
        <h1 style={{textAlign: "center"}} >Contact&nbsp;Us</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("email", "Your email")}
          {this.renderTextBox("message", "Your message")}
          {this.renderButton("Send")}
        </form>
      </div>
    );
  }
}

export default ContactForm;
