import React, {  useEffect, useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { Button } from 'react-bootstrap';

import MySpinner from '../components/common/MySpinner';
import httpService from '../service/httpService';
import config from "../config.json"


function hasMembers(arr){
    return arr != null;
}
function openMember(id){
    window.location.href = "/members/" + id;
}

function Members() {
    let [allMembers, setAllMembers] = useState(null);
    
    useEffect(() => {
        async function fetchData() {
            const {data} =  await httpService.get(config.apiEndPoint + "/members");
            setAllMembers( data.data);
        }
        if (allMembers == null) fetchData();
      });

      const itemContainer = {
        border: "1px solid " + config['colour-schema']['brown-border'], 
        backgroundColor: config['colour-schema']['input-white'],
        backgroundOpacity: "20%",
        display: "grid",
        gridTemplateAreas:` "name button"`,
        columnGap: "50px"
    }

        
        return (
        <div className='container' style={{}}>
            <h1>Members</h1>
            <ListGroup variant="flush" style={{}}>
                {hasMembers(allMembers) ? allMembers.map(
                    member =>
                        <ListGroup.Item style={itemContainer}  key={member["ID"]}>
                            <div style={{ gridArea: "name", justifySelf: "start", alignSelf:"center" }}>
                            { member["firstname"] + " " + member["lastname"]}   
                            </div>
                            <Button style={{ gridArea: "button",  justifySelf: "end" , width:"70px"}} variant="success"  onClick={() => openMember(member["memberID"])} >Details</Button>
                            
                            
                            
                        </ListGroup.Item>
                    
                ) : 
                <MySpinner />
              }

            </ListGroup>

            <div >

            </div>
        
        </div>
        );
    
}


 
export default Members;