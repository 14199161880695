import React from "react";
import Joi from "joi-browser";
import Form from "../components/common/Form";

import httpService from "../service/httpService";
import config from "./../config.json"

class EditForm extends Form {
  state = {
    data: { value: "" },
    token: "",
    errors: {}
  };
  schema = {
    value: Joi.string()
      .required()
      .label("Value")
  };

  componentDidMount(){
      
      try{
        this.state.token = localStorage.getItem("token");
      } catch(e){
        window.location.href = "/noAuthorised"
            
      } 
  }

  handleSubmit =  async (e) =>  {
    e.preventDefault();
    console.log("sending data "+ this.state.token )
    const auth = {
      headers: { Authorization: "bearer " + this.state.token }
    }
    await httpService.post(
        config.apiEndPoint +"/account/update",
        {
            field: this.props.field,
            value: this.state.data.value,
            id: this.props.id
        },
        auth
    );
    // window.location.href = "/accountSettings"
        
        
        
    
  };
  

  render() {
    
    return (
      <div style={{maxWidth: 700, margin: "auto", marginBottom: 20}} id="contact-form">
        <form onSubmit={this.handleSubmit}>
          {this.renderTextBox("value", "")}
          {this.renderButton("Edit")}
        </form>
      </div>
    );
  }
}

export default EditForm;
