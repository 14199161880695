import React from 'react';

import config from "../../config.json"
import Card from 'react-bootstrap/Card';
import  Button  from 'react-bootstrap/Button';

function MyCard({title, text, buttonLink, buttonText, rest}) {
    return (
        <Card style={{border: "2px solid #94866d"}}>
        <Card.Body style={{backgroundColor: config['colour-schema']['lighter-brown']}}>
          <Card.Title>{title}</Card.Title>
          <Card.Text >
            {text}
          </Card.Text>
        </Card.Body>
        <Card.Footer style={{backgroundColor: config['colour-schema']['light-brown']}}>
          <Button variant='success' onClick={()=>window.location.href = buttonLink }>{buttonText}</Button>
        </Card.Footer>
      </Card>
    );
}

export default MyCard;